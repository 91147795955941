import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,

  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';

import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import cloneDeep from "lodash/cloneDeep";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { DataGrid, GridSearchIcon } from "@mui/x-data-grid";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import React, { useEffect } from "react";
import Navigation from "../../sharedComponents/Navigation/Navigation";
import Sidebar from "../../sharedComponents/Sidebar/Sidebar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import GetAllPolicies from "../../api/GetAllPolicies";
import { Colors } from "../theme";
import { borderRadius } from "@mui/system";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangeIcon from "@mui/icons-material/DateRange";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, createSearchParams } from "react-router-dom";
import ViewCompactOutlinedIcon from "@mui/icons-material/ViewCompactOutlined";
import qs from "query-string";
import Swal from "sweetalert2";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import DeleteTransactionAPI from "../../api/DeleteTransaction";
import CachedIcon from "@mui/icons-material/Cached";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useState } from "react";
import { useCallback } from "react";
import AddTransaction from "../ViewTransaction/AddTransaction";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import EditTransactionStatusMgrAPI from "../../api/EditTransactionStatusMgr";
import useAuth from "../../utils/useAuth";
import RestartMLServerAPI from "../../api/RestartMLServer";
import { buildFor, disableExport } from "../../config";
import EditTransactionAssignedToMgrAPI from "../../api/EditTransactionAssignedToMgr";
import CommentDialog from "./CommentDialog";
import CSVDialog from "./CSVDialog";
import DashboardChangeSelectedFiltersAPI from "../../api/DashboardChangeSelectedFilters";
import ReportDialog from "./ReportDialog";
import DashboardChangeSelectedColumnsAPI from "../../api/DashboardChangeSelectedColumns";
import CompletionReportDialog from "./CompletionReportDialog";
const Root = styled("div")(({ theme }) => ({}));
const Container = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "240px auto",
}));

function Dashboard(props) {
  const user = useAuth();
  const [showCSVDialog, setShowCSVDialog] = React.useState(false);
  const [showCompletionReportDialog, setShowCompletionReportDialog] = React.useState(false)
  const [openReportsDialog, setOpenReportsDialog] = React.useState(false)

  /* RS: STATE TO OPEN ADD TRANSACTION DIALOG*/
  const [isAddTransactionDialogOpen, setIsAddTransactionDialogOpen] =
    useState(false);
  const addTransactionDialogRef = React.useRef(isAddTransactionDialogOpen);

  React.useEffect(() => {
    if (isAddTransactionDialogOpen == false && showCSVDialog == false) {
      console.log(isAddTransactionDialogOpen);
      window.addEventListener("resize", debounceRefreshWindow);
      function refreshWindow() {
        window.location.reload();
      }
      function debounceRefreshWindow() {
        let timer;
        clearTimeout(timer);
        timer = setTimeout(refreshWindow, 500);
      }
      return () => {
        window.removeEventListener("resize", debounceRefreshWindow);
      };
    }
  }, [isAddTransactionDialogOpen, showCSVDialog]);

  // console.log(qs.exclude(window.location.search, ['search']))
  // console.log(qs.parse(window.location.search))

  const [forceRefresh, setForceRefresh] = React.useState(Math.random());
  const [rows, setRows] = React.useState([]);
  const [allColumns, setAllColumns] = React.useState(["A", "B", "C", "D"]);
  const [selectedColumns, setSelectedColumns] = React.useState(["A", "B"]);
  const [startDragState, setStartDragState] = React.useState(0);
  // let startDrag = 0;

  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const [paginationData, setPaginationData] = React.useState({
    rowsPerPage: 15,
    rowCount: 0,
    page: 0,
  });
  const [sortingData, setSortingData] = React.useState();
  const [searchString, setSearchString] = React.useState(
    new URLSearchParams(window.location.search).get("search") || ""
  );
  const [itemsAvailable, setItemsAvailable] = React.useState([]);
  const [checkedItemsAvailable, setCheckedItemsAvailable] = React.useState([]);
  const [statusFilterBy, setStatusFilterBy] = React.useState([
    "Pending",
    "Completed",
    "Under Review",
    "AM Pending",
    "AM Completed",
    "Archived",
  ]);

  const [selectedStatusFilterBy, setSelectedStatusFilterBy] = React.useState([])

  const [invokeCommentsDialog, setInvokeCommentsDialog] = React.useState();
  const [checkedStatusFilterBy, setCheckedFilterStatusBy] = React.useState([]);
  const [dateRange, setDateRange] = React.useState();
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [anchorElSelectColumns, setAnchorElSelectColumns] =
    React.useState(null);
  const [anchorElStatusFilter, setAnchorElStatusFilter] = React.useState(null);
  const [anchorElDateRange, setAnchorElDateRange] = React.useState(null);
  const [anchorElStartDateRange, setAnchorElStartDateRange] =
    React.useState(null);
  const [anchorElLastModifiedDateRange, setAnchorElLastModifiedDateRange] =
    React.useState(null);
  const [anchorElEndDateRange, setAnchorElEndDateRange] = React.useState(null);
  const [colWidth, setColWidth] = React.useState(
    new Array(11).fill((window.innerWidth - 300) / 11)
  );
  const [numberOfPages, setNumberOfPages] = React.useState(0);

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleClickSelectColumns = (event) => {
    setAnchorElSelectColumns(event.currentTarget);
  };

  const handleClickStatusFilter = (event) => {
    setAnchorElStatusFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleCloseSelectColumns = () => {
    setAnchorElSelectColumns(null);
  };

  const handleCloseStatusFilter = () => {
    setAnchorElStatusFilter(null);
  };

  const handleClickDateRange = (event) => {
    setAnchorElDateRange(event.currentTarget);
  };

  const handleCloseDateRange = () => {
    setAnchorElDateRange(null);
  };

  const handleClickStartDateRange = (event) => {
    console.log(event);
    setAnchorElStartDateRange(event.currentTarget);
  };

  const handleCloseStartDateRange = () => {
    setAnchorElStartDateRange(null);
  };
  const handleClickLastModifiedDateRange = (event) => {
    console.log(event);
    setAnchorElLastModifiedDateRange(event.currentTarget);
  };

  const handleCloseLastModifiedDateRange = () => {
    setAnchorElLastModifiedDateRange(null);
  };

  const handleClickEndDateRange = (event) => {
    setAnchorElEndDateRange(event.currentTarget);
  };

  const handleCloseEndDateRange = () => {
    setAnchorElEndDateRange(null);
  };

  function showCommentsDialog(data) {
    console.log(data);
    setInvokeCommentsDialog({
      remarks: data.notes,
      transactionId: data.transactionId,
    });
  }

  function restartMLServer() {

    Swal.fire({
      title: "Are you sure?",
      icon: "info",
      text: "This will restart the ML Server.",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      console.log(result);
      if (result.isConfirmed) {
        setIsLoading(true);
        RestartMLServerAPI()
          .then((response) => {
            console.log(response.data);
            if (response.data.hasOwnProperty("error")) {
              console.log("Error");
              Swal.fire({
                title: "Error",
                icon: "error",
                text: response.data.error,
              });
            } else {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: "The server restart request has been sent! Please wait for a while for the server to restart.",
              });
            }

            // if (response.data.success) {
            //   setForceRefresh(Math.random());
            // }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    })




  }

  function handleFilterChecked(item, index) {
    let currentSelections = qs.parse(window.location.search)
      .filterItemsAvailable
      ? qs.parse(window.location.search).filterItemsAvailable
      : [];
    console.log(qs.parse(window.location.search));
    console.log(currentSelections);
    let newCheckedItems = [];

    if (Array.isArray(currentSelections)) {
      for (let index = 0; index < currentSelections.length; index++) {
        const element = currentSelections[index];
        console.log(element);
        newCheckedItems.push(element);
      }
    } else {
      newCheckedItems = [currentSelections];
    }

    if (newCheckedItems.includes(item)) {
      newCheckedItems = newCheckedItems.filter((el) => el !== item);
    } else {
      newCheckedItems.push(item);
    }
    let newData = qs.parse(window.location.search);
    newData.filterItemsAvailable = newCheckedItems;
    // console.log(qs.stringify({ data: ["Business Auto", "UMB"] }, { arrayFormat: 'bracket' }))
    // let currentQuery = qs.parse(window.location.search)

    const options = {
      pathname: window.location.pathname,
      search: qs.stringify(newData),
    };
    navigate(options, { replace: false });
  }

  function handleColumnChecked(item, index) {
    if (selectedColumns.includes(item)) {
      setSelectedColumns((selectedColumns) =>
        selectedColumns.filter((element) => element !== item)
      );
    } else {
      setSelectedColumns([...selectedColumns, item]);
    }
  }

  function handleStatusFilterChecked(item, index) {
    if (selectedStatusFilterBy.includes(item)) {
      setSelectedStatusFilterBy((selectedStatusFilterBy) =>
        selectedStatusFilterBy.filter((element) => element !== item)
      );
    } else {
      setSelectedStatusFilterBy([...selectedStatusFilterBy, item]);
    }
  }
  const mouseDown = (e, index) => {
    // console.log(e)
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // startDrag = e.clientX;
    setStartDragState(e.clientX);
    // console.log("mouseDown ", index, e.clientX, startDragState);
    setActiveIndex(index);
    // console.log(e);
    // startDrag = e.clientX;
    try {
      e.stopPropagation();
    } catch (error) {
      console.log(error);
    }
    try {
      e.nativeEvent.stopImmediatePropagation();
    } catch (error) {
      console.log(error);
    }
    try {
      // console.log(e)
      e.target.stopPropagation();
    } catch (error) {
      // console.log(error);
    }
    try {
      e.target.nativeEvent.stopImmediatePropagation();
    } catch (error) {
      // console.log(error);
    }
  };

  const mouseMove = (e) => {
    e.stopPropagation();

    let width = e.clientX - startDragState;
    const col = stateColumns[activeIndex];
    width = col.width + width <= 130 ? 130 : col.width + width;
    if (width >= 130 && width <= 1200) {
      let newArr = Object.assign([...stateColumns], {
        [activeIndex]: {
          ...stateColumns[activeIndex],
          width: width,
        },
      });
      newArr[activeIndex].width = width;
      setStateColumns(newArr);
    }
  };

  const removeListeners = () => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  };

  const mouseUp = (e) => {
    e.stopPropagation();

    // console.log(
    //   "Mouse Up, drag val , ",
    //   startDragState,
    //   " IDX : ",
    //   activeIndex
    // );
    // columns[0].width = 230;
    // if (startDrag != 0) {
    //   let width = e.clientX - startDrag;
    //   // setStartDrag(0);
    //   startDrag = 0;
    //   columns.map((col, i) => {
    //     if (i === activeIndex) {
    //       console.log("Column match MouseUp, ", col);
    //       // col.width = e.clientX - col.ref.current.offsetLeft;
    //       // console.log(col.,"   =   ",e.clientX);
    //       // columns = JSON.parse(JSON.stringify(columns));
    //       // setStateColumns(JSON.parse(JSON.stringify(columns)))
    //       width = col.width + width <= 130 ? 130 : col.width + width;
    //       console.log("Width is ", width);

    //       if (width >= 130 && width <= 600) {
    //         col.width = width;
    //         // setStateColumns(columns);
    //         const myNewArray = Object.assign([...stateColumns], {
    //           [i]: {
    //             ...stateColumns[i],
    //             width: width,
    //           },
    //         });
    //         setStateColumns(myNewArray);
    //         console.log("Set Columns called");
    //       }

    //       console.log("Column match MouseUp after change, ", col);
    //     }
    //   });
    // }
    setStartDragState(0);
    setActiveIndex(null);
    removeListeners();
  };
  const openFilter = Boolean(anchorElFilter);
  const idFilter = openFilter ? "filter-popover" : undefined;

  const openSelectColumns = Boolean(anchorElSelectColumns);
  const openLastModifiedDateRange = Boolean(anchorElLastModifiedDateRange);
  const idSelectColumns = openSelectColumns
    ? "select-columns-popover"
    : undefined;

  const openStatusFilter = Boolean(anchorElStatusFilter);
  const idStatusFilter = openStatusFilter ? "status-filter-popover" : undefined;
  const idLastModifiedDateRange = openLastModifiedDateRange ? "lastmodifieddate-popover" : undefined;

  const openDateRange = Boolean(anchorElDateRange);
  const openStartDateRange = Boolean(anchorElStartDateRange);
  const openEndDateRange = Boolean(anchorElEndDateRange);
  const idDateRange = openFilter ? "daterabge-popover" : undefined;
  const idStartDateRange = openStartDateRange ? "startdate-popover" : undefined;
  const idEndDateRange = openEndDateRange ? "enddate-popover" : undefined;

  function changeAssignedUser(username, transactionId) {
    setIsLoading(true);
    EditTransactionAssignedToMgrAPI({
      transactionId: transactionId,
      username: username == "N/A" ? null : username,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.hasOwnProperty("error")) {
          console.log("Error");
          Swal.fire({
            title: "Error",
            icon: "error",
            text: response.data.error,
          });
        } else {
          setForceRefresh(Math.random());
        }

        // if (response.data.success) {
        //   setForceRefresh(Math.random());
        // }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function changeStatus(newStatus, transactionId) {
    setIsLoading(true);
    EditTransactionStatusMgrAPI({
      transactionId: transactionId,
      status: newStatus,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.hasOwnProperty("error")) {
          console.log("Error");
          Swal.fire({
            title: "Error",
            icon: "error",
            text: response.data.error,
          });
        } else {
          setForceRefresh(Math.random());
        }

        // if (response.data.success) {
        //   setForceRefresh(Math.random());
        // }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  //attach event to MuiDataGrid-columnSeparator
  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex]);

  useEffect(() => {
    setIsLoading(true);
    let searchParams = new URLSearchParams(window.location.search);
    GetAllPolicies({
      pagination:
        !isNaN(qs.parse(window.location.search).rowsPerPage) &&
          !isNaN(
            parseInt(qs.parse(window.location.search).rowCount) &&
            !isNaN(qs.parse(window.location.search).page)
          )
          ? {
            rowsPerPage: parseInt(
              qs.parse(window.location.search).rowsPerPage
            ),
            rowCount: parseInt(qs.parse(window.location.search).rowCount),
            page: parseInt(qs.parse(window.location.search).page),
          }
          : paginationData,
      search: searchParams.get("search"),
      sorting: qs.parse(window.location.search).sortcolname &&
        qs.parse(window.location.search).sortorder && {
        colName: qs.parse(window.location.search).sortcolname,
        order: qs.parse(window.location.search).sortorder,
      },
      filter: qs.parse(window.location.search).filterItemsAvailable && {
        colName: "itemsAvailable",
        value: Array.isArray(
          qs.parse(window.location.search).filterItemsAvailable
        )
          ? qs.parse(window.location.search).filterItemsAvailable
          : [qs.parse(window.location.search).filterItemsAvailable],
      },
      dateRange: qs.parse(window.location.search).dateRange && {
        startDate: new Date(
          qs.parse(qs.parse(window.location.search).dateRange).startDate
        ),
        endDate: new Date(
          qs.parse(qs.parse(window.location.search).dateRange).endDate
        ),
        key: "selection",
      },
      startDateRange: qs.parse(window.location.search).startDateRange && {
        startDate: new Date(
          qs.parse(qs.parse(window.location.search).startDateRange).startDate
        ),
        endDate: new Date(
          qs.parse(qs.parse(window.location.search).startDateRange).endDate
        ),
        key: "selection",
      },
      endDateRange: qs.parse(window.location.search).endDateRange && {
        startDate: new Date(
          qs.parse(qs.parse(window.location.search).endDateRange).startDate
        ),
        endDate: new Date(
          qs.parse(qs.parse(window.location.search).endDateRange).endDate
        ),
        key: "selection",
      },
      lastModifiedAtDateRange: qs.parse(window.location.search).lastModifiedDateRange && {
        startDate: new Date(
          qs.parse(qs.parse(window.location.search).lastModifiedDateRange).startDate
        ),
        endDate: new Date(
          qs.parse(qs.parse(window.location.search).lastModifiedDateRange).endDate
        ),
        key: "selection",
      },
      testData: 'new'
      // filterStatus:
      //   qs.parse(window.location.search).statusFilterBy &&
      //   (Array.isArray(qs.parse(window.location.search).statusFilterBy)
      //     ? qs.parse(window.location.search).statusFilterBy
      //     : [qs.parse(window.location.search).statusFilterBy]),
      // qs.parse(window.location.search).statusFilterBy &&
      // qs.parse(window.location.search).statusFilterBy,
    })
      .then((response) => {
        let modifiedRows = response.data.result.map((item, index) => {
          return {
            ...item,
            assignableUsers: response.data.assignableAccounts,
          };
        });
        setAllColumns(response.data.columnsAvailable);
        setSelectedColumns(response.data.columnsVisible);
        setRows(modifiedRows);
        setPaginationData(response.data.pagination);
        setItemsAvailable(response.data.itemsAvailable);
        setSelectedStatusFilterBy(response.data.statusVisible)
        setSearchString(response.data.search);
        setNumberOfPages(response.data.numberOfPages);
        console.log(response.data.assignableAccounts);
        const columns = [
          response.data.result[0].hasOwnProperty("recordNumber") && {
            sortable: false,
            field: "recordNumber",
            headerName: buildFor == "TDECU" ? "Policy No." : "Record No.",
            width: colWidth[0],
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value}
                </span>
              );
            },
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {buildFor == "TDECU" ? "Policy No." : "Record No."}
                  </span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 0)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
          },

          response.data.result[0].hasOwnProperty("reference") && {
            field: "reference",
            headerName: buildFor == "TDECU" ? "Customer Name" : "Reference",
            sortable: false,
            width: colWidth[1],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {buildFor == "TDECU" ? "Customer Name" : "Reference"}
                  </span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 1)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("dateAdded") && {
            sortingOrder: ["asc", "desc"],
            field: "dateAdded",
            headerName: "Date Added",
            width: colWidth[2],
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {new Date(params.value).toLocaleString()}
                </span>
              );
            },
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Date Added</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 2)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
          },
          response.data.result[0].hasOwnProperty("itemsAvailable") && {
            field: "itemsAvailable",
            headerName:
              buildFor == "TDECU" ? "Policies Available" : "Items Available",
            sortable: false,
            width: colWidth[3],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {buildFor == "TDECU"
                      ? "Policies Available"
                      : "Items Available"}
                  </span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 3)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("statusRPA") && {
            field: "statusRPA",
            headerName: "Status RPA",
            sortable: false,
            width: colWidth[4],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Status RPA</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 4)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("statusML") && {
            field: "statusML",
            headerName: "Status ML",
            sortable: false,
            width: colWidth[5],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Status ML</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 5)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value}
                </span>
              );
            },
          },

          response.data.result[0].hasOwnProperty("statusChange") && {
            field: "statusChange",
            headerName: "Status Change",
            width: colWidth[6],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Status Change</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 6)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={
                      <span>
                        Pending Changes:{" "}
                        {params.value &&
                          params.value.split("/")[0].split("(")[1]}
                        <br />
                        Success Changes:{" "}
                        {params.value && params.value.split("/")[1]}
                        <br />
                        Faulted Changes:{" "}
                        {params.value &&
                          params.value.split("/")[2].split(")")[0]}
                        <br />
                      </span>
                    }
                  >
                    <span>{params.value}</span>
                  </Tooltip>
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("startDate") && {
            field: "startDate",
            headerName:
              buildFor == "TDECU" ? "Policy Start Date" : "Start Date",
            sortingOrder: ["asc", "desc"],
            width: colWidth[7],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {buildFor == "TDECU" ? "Policy Start Date" : "Start Date"}
                  </span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 7)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {new Date(params.value).toLocaleString()}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("endDate") && {
            field: "endDate",
            headerName: buildFor == "TDECU" ? "Policy End Date" : "End Date",
            sortingOrder: ["asc", "desc"],
            width: colWidth[8],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    {buildFor == "TDECU" ? "Policy End Date" : "End Date"}
                  </span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 8)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {new Date(params.value).toLocaleString()}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("lastModifiedAt") && {
            field: "lastModifiedAt",
            headerName: "Last Modified At",
            sortingOrder: ["asc", "desc"],
            width: colWidth[8],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{"Last Modified At"}</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 8)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value
                    ? new Date(params.value).toLocaleString()
                    : "N/A"}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("lastModifiedBy") && {
            field: "lastModifiedBy",
            headerName: "Last Modified By",
            sortingOrder: ["asc", "desc"],
            width: colWidth[8],
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{"Last Modified By"}</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 8)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {params.value ? params.value : "N/A"}
                </span>
              );
            },
          },
          response.data.result[0].hasOwnProperty("status") && {
            field: "status",
            headerName: "Status",
            sortable: false,
            width: colWidth[9],
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* {params.value} */}
                  <Select
                    // multiple
                    value={params.value}
                    onChange={(e) => {
                      changeStatus(e.target.value, params.row.transactionId);
                    }}
                    // renderValue={(selected) => selected.join(", ")}
                    fullWidth
                    size="small"
                    variant="standard"
                    disableUnderline
                    inputProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    {statusFilterBy.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={{ padding: "0px 8px 0px 8px" }}
                      >
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "14px" }}>{name}</span>
                          }
                          style={{ fontSize: "14px" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </span>
              );
            },
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Status</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 9)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
          },

          response.data.result[0].hasOwnProperty("assignedTo") && {
            field: "assignedTo",
            headerName: "Assigned To",
            sortingOrder: ["asc", "desc"],
            sortable: true,
            width: colWidth[9],
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* {params.value} */}
                  <Select
                    // multiple
                    value={params.value || "N/A"}
                    onChange={(e) => {
                      changeAssignedUser(
                        e.target.value,
                        params.row.transactionId
                      );
                    }}
                    // renderValue={(selected) => selected.join(", ")}
                    fullWidth
                    size="small"
                    variant="standard"
                    disableUnderline
                    inputProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    {params.row.assignableUsers.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={{ padding: "0px 8px 0px 8px" }}
                      >
                        <ListItemText
                          primary={
                            <span style={{ fontSize: "14px" }}>{name}</span>
                          }
                          style={{ fontSize: "14px" }}
                        />
                      </MenuItem>
                    ))}

                    <MenuItem
                      value={"N/A"}
                      style={{ padding: "0px 8px 0px 8px" }}
                    >
                      <ListItemText
                        primary={
                          <span style={{ fontSize: "14px" }}>{"N/A"}</span>
                        }
                        style={{ fontSize: "14px" }}
                      />
                    </MenuItem>
                  </Select>
                </span>
              );
            },
            renderHeader: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Assigned To</span>
                  <span style={{ flex: "1 1 auto" }}></span>
                  <IconButton
                    color="primary"
                    component="span"
                    sx={{
                      width: "10px",
                      height: "36px",
                      position: "absolute",
                      right: "8px",
                      cursor: "col-resize",
                    }}
                    onMouseDown={(e) => mouseDown(e, 9)}
                  >
                    <DragIndicatorIcon color="primary" />
                  </IconButton>
                </div>
              );
            },
          },
          {
            field: "action",
            sortable: false,
            // renderHeader: (params) => {
            //   return (
            //     <span
            //       style={{
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "space-between",
            //         width: "148px",
            //       }}
            //     >
            //       <span>Action</span>
            //       <IconButton
            //         color="primary"
            //         component="span"
            //         sx={{ width: "36px", height: "36px" }}
            //         onClick={() => {
            //           setForceRefresh(Math.random());
            //         }}
            //       >
            //         <CachedIcon color="primary" />
            //       </IconButton>
            //     </span>
            //   );
            // },
            headerName: "Action",
            // width: colWidth[10],
            width: 220,
            renderCell: (params) => {
              return (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title={"Comments"}>
                    <IconButton
                      color="primary"
                      component="span"
                      sx={{
                        width: "36px",
                        height: "36px",
                        marginRight: "16px",
                      }}
                      onClick={() => showCommentsDialog(params.row)}
                    >
                      <CommentOutlinedIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"View"}>
                    <IconButton
                      color="primary"
                      component="span"
                      sx={{ width: "36px", height: "36px", marginRight: "8px" }}
                      onClick={() => {
                        if (params.row.mlServer == 1) {

                          window.location.href = "/view_transaction2?id=" + params.row.transactionId + "&reference=" + params.row.reference
                        }
                        else {

                          window.location.href = "/view_transaction?id=" + params.row.transactionId + "&reference=" + params.row.reference

                        }
                        // navigate(
                        //   "/view_transaction?id=" +
                        //   params.row.transactionId +
                        //   "&reference=" +
                        //   params.row.reference,
                        //   {
                        //     replace: false,
                        //   }
                        // )
                      }
                      }

                    >
                      <img src="/assets/vectors/icn_view.svg" height="24" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Edit"}>
                    <IconButton
                      color="primary"
                      component="span"
                      sx={{ width: "36px", height: "36px", marginRight: "8px" }}
                      onClick={() =>
                        Swal.fire({
                          title: "Are you sure?",
                          icon: "info",
                          text: "On confirmation, the status of the selected transaction will be marked to Under Review.",
                          showCancelButton: true,
                          confirmButtonText: "Confirm",
                        }).then((result) => {
                          console.log(result);
                          if (result.isConfirmed) {
                            if (params.row.mlServer == 1) {
                              // navigate(
                              //   "/validate_transaction2?id=" +
                              //   params.row.transactionId +
                              //   "&reference=" +
                              //   params.row.reference,
                              //   {
                              //     replace: false,
                              //   }
                              // );
                              window.location.href = "/validate_transaction2?id=" + params.row.transactionId + "&reference=" + params.row.reference
                            }
                            else {
                              // navigate(
                              //   "/validate_transaction?id=" +
                              //   params.row.transactionId +
                              //   "&reference=" +
                              //   params.row.reference,
                              //   {
                              //     replace: false,
                              //   }
                              // );
                              window.location.href = "/validate_transaction?id=" + params.row.transactionId + "&reference=" + params.row.reference

                            }
                          }
                        })
                      }
                    >
                      {/* <CreateOutlinedIcon color="primary" /> */}
                      <img src="/assets/vectors/icn_edit.svg" height="24" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={"Delete Transaction"}>
                    <IconButton
                      color="primary"
                      component="span"
                      sx={{ width: "36px", height: "36px", marginRight: "8px" }}
                      onClick={() =>
                        Swal.fire({
                          title: "Are you sure?",
                          icon: "info",
                          text: "On confirmation, this transaction will be deleted forever!",
                          showCancelButton: true,
                          confirmButtonText: "Confirm",
                        }).then((result) => {
                          console.log(result);
                          if (result.isConfirmed) {
                            deleteTransaction(params.row.transactionId);
                          }
                        })
                      }
                    >
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </span>
              );
            },
          },
        ];
        console.log(columns);
        setStateColumns(columns.filter(Boolean));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [window.location.search, forceRefresh]);

  function deleteTransaction(transactionId) {
    setIsLoading(true);
    DeleteTransactionAPI({
      transactionId: transactionId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.success) {
          setForceRefresh(Math.random());
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const [stateColumns, setStateColumns] = React.useState([]);

  function handleSelectColumns() {
    setIsLoading(true);
    DashboardChangeSelectedColumnsAPI({
      columnsVisible: selectedColumns,
    })
      .then((response) => {
        setForceRefresh(Math.random());
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        handleCloseSelectColumns();
      });
  }


  function handleSelectStatusFilterBy() {
    setIsLoading(true);
    DashboardChangeSelectedFiltersAPI({
      visibleStatus: selectedStatusFilterBy,
    })
      .then((response) => {
        setForceRefresh(Math.random());
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        handleCloseStatusFilter();
      });
  }

  /*RS: CLOSE ADD TRANSACTION DIALOG */
  const handleCloseAddTransactionDialog = useCallback(() => {
    setIsAddTransactionDialogOpen(false);
  }, []);
  return (
    <Root>
      <Navigation />
      {openReportsDialog &&
        <ReportDialog statusFilterBy={statusFilterBy} open={openReportsDialog} setOpen={setOpenReportsDialog} />
      }
      {invokeCommentsDialog && (
        <CommentDialog
          data={invokeCommentsDialog}
          setData={setInvokeCommentsDialog}
          setForceRefresh={setForceRefresh}
        />
      )}
      {showCSVDialog && (
        <CSVDialog open={showCSVDialog} setOpen={setShowCSVDialog} />
      )}
      {showCompletionReportDialog && (
        <CompletionReportDialog open={showCompletionReportDialog} setOpen={setShowCompletionReportDialog} />
      )}
      {isAddTransactionDialogOpen && (
        <AddTransaction onClose={handleCloseAddTransactionDialog} />
      )}
      <Container>
        <div style={{ minHeight: "calc(100vh-52px)" }}>
          <Sidebar />
        </div>
        <div style={{ padding: "24px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <Typography variant="h5" component="div">
              Available Policies
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >

              {useAuth().role == "Admin" && (
                <Button
                  variant="outlined"
                  startIcon={<SummarizeIcon />}
                  sx={{
                    borderRadius: "2px",
                    borderWidth: "2px",
                    padding: "4px 16px",
                    marginRight: "8px",
                    "&:hover": { borderWidth: "2px" },
                  }}
                  onClick={() => setShowCompletionReportDialog(true)}
                >
                  Completion Report
                </Button>
              )}

              <Button
                variant="outlined"
                startIcon={<AssessmentOutlinedIcon />}
                sx={{
                  borderRadius: "2px",
                  borderWidth: "2px",
                  padding: "4px 16px",
                  marginRight: "8px",
                  "&:hover": { borderWidth: "2px" },
                }}
                onClick={() => {
                  setOpenReportsDialog(true)
                }}
              >
                Reports
              </Button>


              {!disableExport && (
                <Button
                  variant="outlined"
                  startIcon={<FilePresentOutlinedIcon />}
                  sx={{
                    borderRadius: "2px",
                    borderWidth: "2px",
                    padding: "4px 16px",
                    marginRight: "8px",
                    "&:hover": { borderWidth: "2px" },
                  }}
                  onClick={() => setShowCSVDialog(true)}
                >
                  Download/Export Data
                </Button>
              )}
              {user.permissions.filter(
                (permission) => permission.Value == "Permissions.Dashboard.Edit"
              )[0].Selected == true && (
                  <Button
                    variant="outlined"
                    startIcon={<DnsOutlinedIcon />}
                    sx={{
                      borderRadius: "2px",
                      borderWidth: "2px",
                      padding: "4px 16px",
                      marginRight: "8px",
                      "&:hover": { borderWidth: "2px" },
                    }}
                    onClick={() => restartMLServer()}
                  >
                    Restart ML Server
                  </Button>
                )}

              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  borderRadius: "2px",
                  borderWidth: "2px",
                  padding: "4px 16px",
                  "&:hover": { borderWidth: "2px" },
                }}
                onClick={() => setIsAddTransactionDialogOpen(true)}
              >
                Add
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              style={{
                marginRight: "16px",
                background: Colors.body_bg,
                height: "38px",
                display: "flex",
                alignItems: "center",
                padding: "4px 10px 4px 16px",
                borderRadius: "4px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  let currentQuery = qs.parse(window.location.search);
                  currentQuery.search = searchString;
                  const options = {
                    pathname: window.location.pathname,
                    search: `?${createSearchParams(currentQuery)}`,
                  };
                  navigate(options, { replace: false });
                }}
              >
                <input
                  type="text"
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "4px 8px",
                    borderRadius: "4px",
                  }}
                  placeholder="Search..."
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <IconButton
                  type="submit"
                  style={{
                    padding: "2px",
                    borderRadius: "0px",
                    marginLeft: "8px",
                  }}
                  color="primary"
                  component="button"
                >
                  <GridSearchIcon />
                </IconButton>
              </form>

              <IconButton
                style={{
                  padding: "2px",
                  borderRadius: "0px",
                  marginLeft: "8px",
                }}
                color="primary"
                component="span"
                disabled={!searchString}
                onClick={() => {
                  let currentQuery = qs.exclude(window.location.search, [
                    "search",
                  ]);
                  const options = {
                    pathname: window.location.pathname,
                    search: `?${createSearchParams(currentQuery)}`,
                  };
                  navigate(options, { replace: false });
                  setSearchString("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <IconButton
              onClick={handleClickSelectColumns}
              style={{
                padding: "2px",
                background: Colors.body_bg,
                height: "38px",
                width: "38px",
                borderRadius: "50%",
                marginRight: "16px",
              }}
              color="primary"
              component="span"
            >
              <ViewColumnOutlinedIcon color={true ? "primary" : "disabled"} />
            </IconButton>
            <IconButton
              onClick={handleClickFilter}
              style={{
                padding: "2px",
                background: Colors.body_bg,
                height: "38px",
                width: "38px",
                borderRadius: "50%",
                marginRight: "16px",
              }}
              color="primary"
              component="span"
            >
              <FilterListIcon
                color={
                  qs.parse(window.location.search).filterItemsAvailable
                    ? "primary"
                    : "disabled"
                }
              />
            </IconButton>
            <IconButton
              // onClick={handleClickDateRange}
              onClick={handleClickStatusFilter}
              style={{
                padding: "2px",
                background: Colors.body_bg,
                height: "38px",
                width: "38px",
                borderRadius: "50%",
                marginRight: "16px",
              }}
              color="primary"
              component="span"
            >
              <AssistantPhotoIcon
                color={
                  selectedStatusFilterBy?.length > 0
                    ? "primary"
                    : "disabled"
                }
              />
            </IconButton>
            <Tooltip title={"Filter by Date"} >
              <IconButton
                onClick={handleClickDateRange}
                style={{
                  padding: "2px",
                  background: Colors.body_bg,
                  height: "38px",
                  marginRight: '8px',
                  width: "38px",
                  borderRadius: "50%",
                }}
                color="primary"
                component="span"
              >
                <DateRangeIcon
                  color={
                    qs.parse(window.location.search).dateRange
                      ? "primary"
                      : "disabled"
                  }
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Filter by Start Date"}>
              <IconButton
                onClick={handleClickStartDateRange}
                style={{
                  padding: "2px",
                  background: Colors.body_bg,
                  marginRight: '8px',

                  height: "38px",
                  width: "38px",
                  borderRadius: "50%",
                }}
                color="primary"
                component="span"
              >
                <EventAvailableRoundedIcon
                  color={
                    qs.parse(window.location.search).startDateRange
                      ? "primary"
                      : "disabled"
                  }
                />
              </IconButton>
            </Tooltip>

            <Tooltip title={"Filter by End Date"}>
              <IconButton
                onClick={handleClickEndDateRange}
                style={{
                  padding: "2px",
                  background: Colors.body_bg,
                  height: "38px",
                  width: "38px",
                  borderRadius: "50%",
                  marginRight: '8px',

                }}
                color="primary"
                component="span"
              >
                <EventBusyRoundedIcon
                  color={
                    qs.parse(window.location.search).endDateRange
                      ? "primary"
                      : "disabled"
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Filter by Last Modified Date"}>
              <IconButton
                onClick={handleClickLastModifiedDateRange}
                style={{
                  padding: "2px",
                  background: Colors.body_bg,
                  height: "38px",
                  width: "38px",
                  borderRadius: "50%",
                  marginRight: '8px',

                }}
                color="primary"
                component="span"
              >
                <EventRepeatIcon
                  color={
                    qs.parse(window.location.search).lastModifiedDateRange
                      ? "primary"
                      : "disabled"
                  }
                />
              </IconButton>
            </Tooltip>
            <div
              style={{
                marginLeft: "16px",
                background: Colors.body_bg,
                height: "38px",
                display: "flex",
                alignItems: "center",
                padding: "4px 16px 4px 16px",
                borderRadius: "4px",
              }}
            >
              <Select
                disableUnderline
                value={paginationData.page}
                MenuProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                inputProps={{
                  style: {
                    fontSize: "14px",
                    paddingTop: "5px",
                  },
                }}
                SelectDisplayProps={{
                  style: {
                    marginTop: "2px",
                    padding: "0px 20px 0px 2px",
                  },
                }}
                variant="standard"
                label="Age"
                style={{
                  background: "#ffffff",
                  height: "24px",
                  padding: "0px 4px 0px 8px",
                  borderRadius: "4px",
                  fontSize: "14px",
                }}
                // onChange={handleChange}
                onChange={(e) => {
                  let currentQuery = qs.parse(window.location.search);
                  currentQuery.page = e.target.value;
                  currentQuery.rowsPerPage = paginationData.rowsPerPage;
                  currentQuery.rowCount = paginationData.rowCount;
                  const options = {
                    pathname: window.location.pathname,
                    search: `?${createSearchParams(currentQuery)}`,
                  };
                  navigate(options, { replace: false });
                }}
              >
                {Array(numberOfPages + 1)
                  .fill("")
                  .map((item, index) => (
                    <MenuItem value={index} key={index}>
                      <span style={{ fontSize: "14px" }}>Page {index + 1}</span>
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <IconButton
              color="primary"
              component="span"
              sx={{ marginLeft: "16px" }}
              onClick={() => {
                setForceRefresh(Math.random());
              }}
            >
              <CachedIcon color="primary" />
            </IconButton>

            <Popover
              elevation={1}
              id={idFilter}
              open={openFilter}
              anchorEl={anchorElFilter}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List component="nav" dense>
                {itemsAvailable.map((item, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemButton
                      role={undefined}
                      onClick={(e) => handleFilterChecked(item, index)}
                      // onClick={handleToggle(value)}
                      dense
                    >
                      <ListItemIcon
                        style={{ minWidth: "32px", height: "24px" }}
                      >
                        <Checkbox
                          edge="start"
                          // checked={false}
                          // checked={checked.indexOf(value) !== -1}
                          checked={
                            qs.parse(window.location.search)
                              .filterItemsAvailable == item ||
                            (qs.parse(window.location.search)
                              .filterItemsAvailable &&
                              qs
                                .parse(window.location.search)
                                .filterItemsAvailable.includes(item)) ||
                            false
                          }
                          tabIndex={-1}
                          disableRipple
                        // inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={index} primary={item} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <Divider style={{ marginBottom: "8px" }} />
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    style={{ padding: "0px", margin: "0px" }}
                    // onClick={e => handleFilterChecked(item, index)}
                    // onClick={handleToggle(value)}
                    dense
                  >
                    <ListItemText
                      style={{ padding: "0px", margin: "0px" }}
                      primary={
                        <Button
                          fullWidth
                          size="small"
                          onClick={(e) => {
                            let currentQuery = qs.exclude(
                              window.location.search,
                              ["filterItemsAvailable"]
                            );
                            const options = {
                              pathname: window.location.pathname,
                              search: `?${createSearchParams(currentQuery)}`,
                            };
                            navigate(options, { replace: false });
                          }}
                        >
                          Clear
                        </Button>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>

            <Popover
              elevation={1}
              id={idSelectColumns}
              open={openSelectColumns}
              anchorEl={anchorElSelectColumns}
              onClose={handleCloseSelectColumns}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List component="nav" dense>
                {allColumns.map((item, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemButton
                      role={undefined}
                      onClick={(e) => handleColumnChecked(item, index)}
                      // onClick={handleToggle(value)}
                      dense
                    >
                      <ListItemIcon
                        style={{ minWidth: "32px", height: "24px" }}
                      >
                        <Checkbox
                          edge="start"
                          // checked={false}
                          // checked={checked.indexOf(value) !== -1}
                          checked={selectedColumns.includes(item)}
                          tabIndex={-1}
                          disableRipple
                        // inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={index}
                        primary={
                          (item == "recordNumber" && buildFor == "TDECU"
                            ? "Policy Number"
                            : item == "recordNumber" &&
                            buildFor !== "TDECU" &&
                            "Record No.") ||
                          (item == "reference" && buildFor == "TDECU"
                            ? "Customer Name"
                            : item == "reference" &&
                            buildFor !== "TDECU" &&
                            "Reference") ||
                          (item == "dateAdded" && "Date Added") ||
                          (item == "itemsAvailable" && buildFor == "TDECU"
                            ? "Policies Available"
                            : item == "itemsAvailable" &&
                            buildFor !== "TDECU" &&
                            "Items Available") ||
                          (item == "statusRPA" && "Status RPA") ||
                          (item == "statusML" && "Status ML") ||
                          (item == "statusChange" && "Status Change") ||
                          (item == "lastModifiedBy" && "Last Modified By") ||
                          (item == "lastModifiedAt" && "Last Modified At") ||
                          (item == "assignedTo" && "Assigned To") ||
                          (item == "startDate" && buildFor == "TDECU"
                            ? "Policy Start Date"
                            : item == "startDate" &&
                            buildFor !== "TDECU" &&
                            "Start Date") ||
                          (item == "endDate" && buildFor == "TDECU"
                            ? "Policy End Date"
                            : item == "endDate" &&
                            buildFor !== "TDECU" &&
                            "End Date") ||
                          (item == "status" && "Status") ||
                          item
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
                <Divider style={{ marginBottom: "8px" }} />
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    style={{ padding: "0px", margin: "0px" }}
                    onClick={(e) => handleSelectColumns(e)}
                    dense
                  >
                    <ListItemText
                      style={{ padding: "0px", margin: "0px" }}
                      primary={
                        <Button fullWidth size="small">
                          Confirm
                        </Button>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    style={{ padding: "0px", margin: "0px" }}
                    onClick={(e) => setSelectedColumns([])}
                    dense
                  >
                    <ListItemText
                      style={{ padding: "0px", margin: "0px" }}
                      primary={
                        <Button fullWidth size="small">
                          Clear
                        </Button>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>

            {/* Status Filter */}
            <Popover
              elevation={1}
              id={idStatusFilter}
              open={openStatusFilter}
              anchorEl={anchorElStatusFilter}
              onClose={handleCloseStatusFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List component="nav" dense>
                {/* <FormControl style={{ padding: "16px" }}>
                  <RadioGroup
                    name="radio-buttons-group"
                    onChange={(e) => {
                      console.log(e.target.value);
                      let newData = qs.parse(window.location.search);
                      newData.statusFilterBy = e.target.value;
                      const options = {
                        pathname: window.location.pathname,
                        search: qs.stringify(newData),
                      };
                      console.log(options);
                      navigate(options, { replace: false });
                    }}
                    value={
                      qs.parse(window.location.search).statusFilterBy || null
                    }
                  >
                    {statusFilterBy.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={item}
                        control={<Radio />}
                        label={item}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}

                {statusFilterBy.map((item, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={(e) => handleStatusFilterChecked(item, index)}
                      // onClick={handleToggle(value)}
                      dense
                    >
                      <ListItemIcon
                        style={{ minWidth: "32px", height: "24px" }}
                      >
                        <Checkbox
                          edge="start"
                          checked={selectedStatusFilterBy?.includes(item) ||
                            false
                          }
                          tabIndex={-1}
                          disableRipple
                        // inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={index} primary={item} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <Divider style={{ marginBottom: "8px" }} />
                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    style={{ padding: "0px", margin: "0px" }}
                    onClick={(e) => handleSelectStatusFilterBy(e)}
                    dense
                  >
                    <ListItemText
                      style={{ padding: "0px", margin: "0px" }}
                      primary={
                        <Button fullWidth size="small">
                          Confirm
                        </Button>
                      }
                    />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                  <ListItemButton
                    role={undefined}
                    style={{ padding: "0px", margin: "0px" }}
                    onClick={(e) => setSelectedStatusFilterBy([])}
                    dense
                  >
                    <ListItemText
                      style={{ padding: "0px", margin: "0px" }}
                      primary={
                        <Button fullWidth size="small">
                          Clear
                        </Button>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Popover>

            <Popover
              elevation={1}
              id={idDateRange}
              open={openDateRange}
              anchorEl={anchorElDateRange}
              onClose={handleCloseDateRange}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DateRangePicker
                  ranges={
                    qs.parse(window.location.search).dateRange
                      ? [
                        {
                          startDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).dateRange
                            ).startDate
                          ),
                          endDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).dateRange
                            ).endDate
                          ),
                          key: "selection",
                        },
                      ]
                      : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]
                  }
                  onChange={(e) => {
                    console.log(e.selection);
                    let currentQuery = qs.parse(window.location.search);
                    currentQuery.dateRange = qs.stringify(e.selection);
                    console.log(currentQuery);
                    const options = {
                      pathname: window.location.pathname,
                      search: qs.stringify(currentQuery),
                    };
                    navigate(options, { replace: false });
                  }}
                />
                <Button
                  onClick={(e) => {
                    let currentQuery = qs.exclude(window.location.search, [
                      "dateRange",
                    ]);
                    const options = {
                      pathname: window.location.pathname,
                      search: `?${createSearchParams(currentQuery)}`,
                    };
                    navigate(options, { replace: false });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Popover>

            <Popover
              elevation={1}
              id={idStartDateRange}
              open={openStartDateRange}
              anchorEl={anchorElStartDateRange}
              onClose={handleCloseStartDateRange}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              Start Date Range
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DateRangePicker
                  ranges={
                    qs.parse(window.location.search).startDateRange
                      ? [
                        {
                          startDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).startDateRange
                            ).startDate
                          ),
                          endDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).startDateRange
                            ).endDate
                          ),
                          key: "selection",
                        },
                      ]
                      : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]
                  }
                  onChange={(e) => {
                    console.log(e.selection);
                    let currentQuery = qs.parse(window.location.search);
                    currentQuery.startDateRange = qs.stringify(e.selection);
                    console.log(currentQuery);
                    const options = {
                      pathname: window.location.pathname,
                      search: qs.stringify(currentQuery),
                    };
                    navigate(options, { replace: false });
                  }}
                />
                <Button
                  onClick={(e) => {
                    let currentQuery = qs.exclude(window.location.search, [
                      "startDateRange",
                    ]);
                    const options = {
                      pathname: window.location.pathname,
                      search: `?${createSearchParams(currentQuery)}`,
                    };
                    navigate(options, { replace: false });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Popover>

            <Popover
              elevation={1}
              id={idEndDateRange}
              open={openEndDateRange}
              anchorEl={anchorElEndDateRange}
              onClose={handleCloseEndDateRange}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DateRangePicker
                  ranges={
                    qs.parse(window.location.search).endDateRange
                      ? [
                        {
                          startDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).endDateRange
                            ).startDate
                          ),
                          endDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).endDateRange
                            ).endDate
                          ),
                          key: "selection",
                        },
                      ]
                      : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]
                  }
                  onChange={(e) => {
                    console.log(e.selection);
                    let currentQuery = qs.parse(window.location.search);
                    currentQuery.endDateRange = qs.stringify(e.selection);
                    console.log(currentQuery);
                    const options = {
                      pathname: window.location.pathname,
                      search: qs.stringify(currentQuery),
                    };
                    navigate(options, { replace: false });
                  }}
                />
                <Button
                  onClick={(e) => {
                    let currentQuery = qs.exclude(window.location.search, [
                      "endDateRange",
                    ]);
                    const options = {
                      pathname: window.location.pathname,
                      search: `?${createSearchParams(currentQuery)}`,
                    };
                    navigate(options, { replace: false });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Popover>


            <Popover
              elevation={1}
              id={idLastModifiedDateRange}
              open={openLastModifiedDateRange}
              anchorEl={anchorElLastModifiedDateRange}
              onClose={handleCloseLastModifiedDateRange}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DateRangePicker
                  ranges={
                    qs.parse(window.location.search).lastModifiedDateRange
                      ? [
                        {
                          startDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).lastModifiedDateRange
                            ).startDate
                          ),
                          endDate: new Date(
                            qs.parse(
                              qs.parse(window.location.search).lastModifiedDateRange
                            ).endDate
                          ),
                          key: "selection",
                        },
                      ]
                      : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]
                  }
                  onChange={(e) => {
                    console.log(e.selection);
                    let currentQuery = qs.parse(window.location.search);
                    currentQuery.lastModifiedDateRange = qs.stringify(e.selection);
                    console.log(currentQuery);
                    const options = {
                      pathname: window.location.pathname,
                      search: qs.stringify(currentQuery),
                    };
                    navigate(options, { replace: false });
                  }}
                />
                <Button
                  onClick={(e) => {
                    let currentQuery = qs.exclude(window.location.search, [
                      "lastModifiedDateRange",
                    ]);
                    const options = {
                      pathname: window.location.pathname,
                      search: `?${createSearchParams(currentQuery)}`,
                    };
                    navigate(options, { replace: false });
                  }}
                >
                  Clear
                </Button>
              </div>
            </Popover>
          </div>

          <DataGrid
            sx={{ border: "none" }}
            rows={rows}
            columns={stateColumns}
            disableColumnMenu
            paginationMode="server"
            getRowId={(row) => row.transactionId}
            // hideFooterPagination={true}
            autoHeight
            loading={isLoading}
            disableSelectionOnClick
            rowCount={paginationData.rowCount}
            page={parseInt(qs.parse(window.location.search).page) || 0}
            sortingMode="server"
            sortModel={
              qs.parse(window.location.search).sortcolname &&
                qs.parse(window.location.search).sortorder
                ? [
                  {
                    field: qs.parse(window.location.search).sortcolname,
                    sort: qs.parse(window.location.search).sortorder,
                  },
                ]
                : []
            }
            onSortModelChange={(data) => {
              console.log(data);
              let currentQuery = qs.parse(window.location.search);
              currentQuery.sortcolname = data[0].field;
              currentQuery.sortorder = data[0].sort;
              const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(currentQuery)}`,
              };
              navigate(options, { replace: false });
            }}
            onPageChange={(data) => {
              let currentQuery = qs.parse(window.location.search);
              currentQuery.page = data;
              currentQuery.rowsPerPage = paginationData.rowsPerPage;
              currentQuery.rowCount = paginationData.rowCount;
              const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(currentQuery)}`,
              };
              navigate(options, { replace: false });
            }}
            onPageSizeChange={(data) => {
              let currentQuery = qs.parse(window.location.search);
              currentQuery.page = paginationData.page;
              currentQuery.rowsPerPage = data;
              currentQuery.rowCount = paginationData.rowCount;
              const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(currentQuery)}`,
              };
              navigate(options, { replace: false });
            }}
            pageSize={paginationData.rowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 50, 100]}
          />
        </div>
      </Container>
    </Root>
  );
}

export default Dashboard;
