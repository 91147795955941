import styled from "@emotion/styled";
import {
  Alert,
  Button,
  ButtonBase,
  CircularProgress,
  InputBase,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { display } from "@mui/system";
import React from "react";
import { Colors } from "../theme";
import { useFormControl } from "@mui/material/FormControl";
import PersonIcon from "@mui/icons-material/Person";
import LockResetIcon from "@mui/icons-material/LockReset";
import LoginApi, { LoginMS } from "../../api/Login";
import useAuth from "../../utils/useAuth";
import { useMsal } from "@azure/msal-react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SendForgotPasswordMailApi from "../../api/SendForgotPasswordMail";

const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
}));

const loginRequest = {
  scopes: ["User.Read"],
};

function ForgotPasswordPage() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");

  function handleForgotPassword(e) {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    SendForgotPasswordMailApi({
      email: email,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.success == true) {
          setSuccessMsg(
            "Please check your mailbox for password reset instructions."
          );
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Root>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div style={{ width: "280px" }}>
          <div style={{ marginBottom: "24px", textAlign: "center" }}>
            <h4>Welcome to</h4>
            <h3>RiYSA Document Validator</h3>
          </div>
          <form onSubmit={(e) => handleForgotPassword(e)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid " + Colors.primary,
              }}
            >
              <PersonIcon color="primary" />
              <InputBase
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
                fullWidth={true}
                type="email"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter your email address"
              />
            </div>

            {error !== "" && (
              <Alert severity="error" style={{ marginTop: "16px" }}>
                {error}
              </Alert>
            )}
            {successMsg !== "" && (
              <Alert severity="info" style={{ marginTop: "16px" }}>
                {successMsg}
              </Alert>
            )}

            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ marginTop: "16px" }}
                type="submit"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={23} />
                ) : (
                  "SEND PASSWORD RESET LINK"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          background: Colors.primary,
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            position: "relative",
            overflow: "hidden",
            position: "absolute",
            width: "100%",
          }}
        >
          <div
            style={{
              height: "240px",
              width: "240px",
              background: "white",
              borderRadius: "50%",
              opacity: "0.2",
              position: "absolute",
              top: "-120px",
              left: "-120px",
            }}
          ></div>
          <div
            style={{
              height: "240px",
              width: "240px",
              background: "white",
              borderRadius: "50%",
              opacity: "0.2",
              position: "absolute",
              top: "120px",
              right: "-120px",
            }}
          ></div>
          <div
            style={{
              height: "140px",
              width: "140px",
              background: "white",
              borderRadius: "50%",
              opacity: "0.2",
              position: "absolute",
              top: "300px",
              left: "-60px",
            }}
          ></div>
          <img
            src={"/assets/vectors/wave1.svg"}
            style={{
              width: "100%",
              opacity: "0.2",
              position: "absolute",
              bottom: "10%",
            }}
          />
        </div>
        <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              style={{
                background: "#ffffff",
                width: "fit-content",
                padding: "32px 25px 32px 39px",
                borderRadius: "50%",
              }}
            >
              <img
                src="/assets/vectors/logo_blue.svg"
                style={{ height: "120px", width: "120px" }}
              />
            </div>
            <Typography
              variant="h5"
              style={{ color: "white", marginTop: "24px" }}
            >
              RiYSA Document Validator
            </Typography>
          </div>
        </div>
      </div>
    </Root>
  );
}

export default ForgotPasswordPage;
